import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon component
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'; // Import the brand icons
import '../index.css'; 

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu open/close
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false); // Close menu
  };

  return (
    <header className="bg-black text-white fixed top-0 left-0 w-full z-50">
      {/* Web Menu (for md and above screens) */}
      <nav className="hidden md:flex justify-between items-center px-24 py-4">
        <a href="/Video" className="text-lg md:text-[23px] lg:text-[23px] font-light">Film</a>
        <a href="/" className="text-lg md:text-[23px] lg:text-[23px] roboto-light font-light">Oguzcan Turan</a>
        <a href="/Social" className="text-lg md:text-[23px] lg:text-[23px] font-light text-right">Social Media</a>
      </nav>

      {/* Mobile and Tablet Menu (for screens below md) */}
      <nav className="md:hidden flex justify-between items-center px-4 py-8">
        {/* Hamburger Menu and "MENU" text, aligned to the right */}
        <div className="flex items-center ml-auto"> {/* Added ml-auto to align to the right */}
          <button onClick={handleMenuToggle} className="focus:outline-none flex items-center">
            <svg
              className="w-8 h-8 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
            <span className="ml-2 text-lg">MENU</span>
          </button>
        </div>
      </nav>

      {/* Fullscreen Menu - Mobile and Tablet */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-90 z-40 flex flex-col justify-center items-center pt-8">
          {/* Close Button */}
          <button onClick={handleCloseMenu} className="absolute top-4 right-4 focus:outline-none">
            <svg
              className="w-8 h-8 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>

          {/* Menu Links */}
          <nav className="flex flex-col items-center gap-5 mt-4"> {/* Added gap-5 for 20px gap */}
            <a href="/Video" className="text-2xl font-light text-white">Film</a>
            <a href="/" className="text-2xl font-light text-white">Oguzcan Turan</a>
            <a href="/Social" className="text-2xl font-light text-white">Social Media Marketing</a>
          </nav>

          {/* Social Media Icons at the Bottom */}
          <div className="flex justify-center gap-5 mt-40"> {/* Added mt-40 for 10rem gap */}
            <a href="https://www.facebook.com/oguzcan.turan" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} className="text-white w-6 h-6" />
            </a>
            <a href="https://www.instagram.com/oguzcanturan/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} className="text-white w-6 h-6" />
            </a>
            <a href="https://www.linkedin.com/in/o%C4%9Fuzcan-turan-343290218?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} className="text-white w-6 h-6" />
            </a>
          </div>
        </div>
      )}
    </header>
  );
}
