import React from 'react';
import Brand from './Brand';

export default function SocialText4() {
  return (
    <>
      <div className='relative bg-[#cccccc] w-full md:w-5/12 h-24 flex justify-center items-center'>
        <h1 className='text-2xl md:text-4xl text-black font-bold font-poppins ' data-aos="fade-up">
          References
        </h1>
      </div>
      <Brand />
    </>
  );
}
