import React from 'react';

export default function SocialText() {
  return (
    <>
  <div className='relative bg-[#cccccc] w-full md:w-7/12 h-36 md:h-48 lg:h-24'>
  <h1 className='absolute top-0/2 pt-10 md:top-0 font-poppins md:pt-16 lg:top-1/2 lg:pt-0 left-0 md:left-0 lg:left-1/2 transform -translate-y-1/2 lg:-translate-x-1/2 text-xl md:text-3xl c text-black text-left lg:text-center leading-tight pl-4 md:pl-10 lg:pl-0' data-aos="fade-up">
    <span className="block">Hi there! I'm</span>
    <span className="font-extrabold block">Oğuzcan Turan</span>
  </h1>
</div>




    </>
  );
}
