import React from 'react';

export default function Brand() {
  return (
    <div className="overflow-hidden pt-28 pb-14">
      {/* Kaydırma animasyonu için konteyner */}
      <div className="flex items-center justify-center animate-marquee space-x-8">
        {/* 6 adet logo */}
        <img src="/images/akm-logo.png" alt="logo1" className="h-16 w-auto" />
        <img src="/images/Artboard 1.png" alt="logo2" className="h-16 w-auto max-w-52" />
        <img src="/images/Celenes by sweden.png" alt="logo3" className="h-16 w-auto" />
        <img src="/images/evidea-header-logo.png" alt="logo4" className="h-16 w-auto" />
        <img src="/images/ferrero.png" alt="logo5" className="h-16 w-auto" />
       
        <img src="/images/Huawei_logo_icon_170010.png" alt="logo4" className="h-16 w-auto" />
        <img src="/images/LJAZEERA.png" alt="logo5" className="h-16 w-auto" />
        <img src="/images/Logo_Nutella.svg.png" alt="logo6" className="h-16 w-auto" />
        <img src="/images/Pastel-Cosmetics-Logo-03.png" alt="logo5" className="h-16 w-auto" />
        <img src="/images/CIFT-SATIR-YATAY-TIRE.png" alt="logo6" className="h-48 w-auto" />
        {/* Logoları tekrarlıyoruz, böylece sonsuz kaydırma sağlanır */}
        <img src="/images/akm-logo.png" alt="logo1" className="h-16 w-auto" />
        <img src="/images/Artboard 1.png" alt="logo2" className="h-16 w-auto max-w-52" />
        <img src="/images/Celenes by sweden.png" alt="logo3" className="h-16 w-auto" />
        <img src="/images/evidea-header-logo.png" alt="logo4" className="h-16 w-auto" />
        <img src="/images/ferrero.png" alt="logo5" className="h-16 w-auto" />
        
        <img src="/images/Huawei_logo_icon_170010.png" alt="logo4" className="h-16 w-auto" />
        <img src="/images/LJAZEERA.png" alt="logo5" className="h-16 w-auto" />
        <img src="/images/Logo_Nutella.svg.png" alt="logo6" className="h-16 w-auto" />
        <img src="/images/Pastel-Cosmetics-Logo-03.png" alt="logo5" className="h-16 w-auto" />
        <img src="/images/CIFT-SATIR-YATAY-TIRE.png" alt="logo6" className="h-48 w-auto" />
      </div>
    </div>
  );
}
