import React, { useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import '../Video.css';

export default function VideoPage() {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const isMobileOrTablet = () => {
    const screenSize = window.innerWidth;
    return screenSize < 1024; // Tablet ve mobil ekran boyutları için kontrol
  };

  const videos = useMemo(
    () => [
      {
        id: 1,
        src: '/videos/OCT_showreel_Onizleme.mp4',
        embedCode: `
        <div class="video-container">
          <iframe
            width="960"
            height="515"
            src="https://www.youtube.com/embed/BLJOXmGTlas?si=1hk2xr2sTqZKjbQT"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      `,
        title: 'Oguzcan Turan',
        description: 'Showreel 2024',
        details:
          'This showreel brings together videos I’ve both shot and edited, showcasing a range of creative and technical skills. From the initial spark of an idea to the final cut, each project is a journey to create something engaging, impactful, and visually stunning. Every shot, every transition, is crafted with purpose and passion. Ready to see your brand’s story come to life on screen?',
        isVertical: false,
      },
      {
        id: 2,
        src: '/videos/Beourguest_onizleme.mp4',
        embedCode: `
    <div class="video-container">
      <iframe
        width="960"
        height="515"
        src="https://www.youtube.com/embed/EO8589y-sJQ?si=bf426UQksmJbzb_3"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </div>
  `,
        title: 'Oguzcan Turan',
        description: 'Be Our Guest Series Showreel',
        details: `As part of the Go Türkiye brand, I had the pleasure of working on the "Be Our Guest" project, a comprehensive video series designed to showcase the rich culture and vibrant experiences available in Turkey. I was deeply involved in every stage of the project, from pre-production and filming to editing the entire video series.`,
        isVertical: false,
      },
      {
        id: 3,
        src: '/videos/SM_Showreel_onizleme.mp4',
        embedCode: `
    <div class="video-container">
      <iframe
        width="960"
        height="515"
        src="https://www.youtube.com/embed/TIdDyUqR1bE?si=4RN5_6cof8vLqQ4B"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </div>
  `,
        title: 'Oguzcan Turan',
        description: 'Social Media Vertical Videos Showreel',
        details: `In 2024, I had the opportunity to create and edit a collection of dynamic vertical videos customized for social media platforms. This showreel highlights the creative projects I’ve developed for various brands, including ad campaigns, creative reels, and concert videos.`,
        isVertical: false,
      },
      {
        id: 4,
        src: '/videos/Can_Bonomo_Konser_v2_1_1.mp4',
        embedCode: `
        <div class="video-container">
          <iframe width="960" height="515" src="https://www.youtube.com/embed/CUNalaOJG2c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      `,
        title: 'Oguzcan Turan',
        description: '',
        details: '',
        isVertical: true,
        poster: "/images/Can_Bonomo_Konser_v2_1_1.mp4.00_00_39_29.Still001.jpg" 
      },
      {
        id: 5,
        src: '/videos/Canan_Anderson_Reels_1_1.mp4',
        embedCode: `
        <div class="video-container">
          <iframe width="960" height="515" src="https://www.youtube.com/embed/cyx-BblAg58" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      `,
        title: 'Oguzcan Turan',
        description: '',
        details: '',
        isVertical: true,
        poster: "/images/Canan_Anderson_Reels_1_1.mp4.00_00_30_23.Still001.jpg"
      },
      {
        id: 6,
        src: '/videos/Day of Happiness_1.mp4',
        embedCode: `
        <div class="video-container">
          <iframe width="960" height="515" src="https://www.youtube.com/embed/tIQCgW1qbKE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      `,
        title: 'Oguzcan Turan',
        description: '',
        details: '',
        isVertical: true,
        poster: "/images/Day of Happiness_1.mp4.00_00_17_19.Still001.jpg"
      },
      {
        id: 7,
        src: '/videos/Edit 1_1.mp4',
        embedCode: `
        <div class="video-container">
          <iframe width="960" height="515" src="https://www.youtube.com/embed/ZPWBKobL8NY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      `,
        title: 'Oguzcan Turan',
        description: '',
        details: '',
        isVertical: true,
        poster: "/images/Edit 1_1.mp4.00_14_01_16.Still001.jpg"
      },
      {
        id: 8,
        src: '/videos/Edit 3_1.mp4',
        embedCode: `
        <div class="video-container">
          <iframe width="960" height="515" src="https://www.youtube.com/embed/rMi9LbJXpgI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      `,
        title: 'Oguzcan Turan',
        description: '',
        details: '',
        isVertical: true,
        poster: "/images/Edit 3_1.mp4.00_04_22_03.Still001.jpg",
      },
      {
        id: 9,
        src: '/videos/Edit 4_1.mp4',
        embedCode: `
        <div class="video-container">
          <iframe width="960" height="515" src="https://www.youtube.com/embed/IYrVh0Y9SL4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      `,
        title: 'Oguzcan Turan',
        description: '',
        details: '',
        isVertical: true,
        poster: "/images/Edit 4_1.mp4.00_08_44_11.Still001.jpg"
      },
      {
        id: 10,
        src: '/videos/Edit 5_1.mp4',
        embedCode: `
        <div class="video-container">
          <iframe width="960" height="515" src="https://www.youtube.com/embed/WiuGFI1zGoQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      `,
        title: 'Oguzcan Turan',
        description: '',
        details: '',
        isVertical: true,
        poster: "/images/Edit 5_1.mp4.00_16_50_15.Still001.jpg"
      },
      {
        id: 11,
        src: '/videos/KB_HBCI_COOL_ISTANBUL_MAIN_240801_60SN_9X16_WEB_YK_1.mp4',
        embedCode: `
          <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
            <iframe width="337" height="599" 
              src="https://www.youtube.com/embed/5_Fo0RTcHVU" 
              title="COOL ISTANBUL" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerpolicy="strict-origin-when-cross-origin" 
              allowfullscreen>
            </iframe>
          </div>
        `,
        title: 'Oguzcan Turan',
        description: '',
        details: '',
        isVertical: true,
        poster: "/images/KB_HBCI_COOL_ISTANBUL_MAIN_240801_60SN_9X16_WEB_YK_1.mp4.00_00_03_09.Still001.jpg",
      },
      {
        id: 12,
        src: '/videos/Summer_is_coming_v2_1.mp4',
        embedCode: `
        <div class="video-container">
          <iframe width="960" height="515" src="https://www.youtube.com/embed/KXgtf-h1O60" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      `,
        title: 'Oguzcan Turan',
        description: '',
        details: '',
        isVertical: true,
        poster: "/images/Summer_is_coming_v2_1.mp4.00_00_00_00.Still001.jpg"
      },
    ],
    []
  );

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isVisible]);

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setIsVisible(true);
  };

  const handleExitFullscreen = () => {
    setIsVisible(false);
    setTimeout(() => {
      setSelectedVideo(null);
    }, 500);
  };

  const handleMouseOver = (e) => {
    if (!isMobileOrTablet() && e.target.readyState >= 3) {
      e.target.play();
    } else {
      e.target.addEventListener(
        'canplay',
        () => {
          e.target.play();
        },
        { once: true }
      );
    }
  };

  const handleMouseOut = (e) => {
    if (!isMobileOrTablet()) {
      e.target.pause();
      e.target.currentTime = 0;
    }
  };

  const handleNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === videos.filter((video) => video.isVertical).length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? videos.filter((video) => video.isVertical).length - 1 : prevIndex - 1
    );
  };

  const visibleVideos = () => {
    const screenSize = window.innerWidth;
    const verticalVideos = videos.filter((video) => video.isVertical);

    if (screenSize < 768) {
      return [verticalVideos[currentIndex]];
    }

    if (screenSize >= 768 && screenSize < 1024) {
      const nextIndex = (currentIndex + 1) % verticalVideos.length;
      return [verticalVideos[currentIndex], verticalVideos[nextIndex]];
    }

    const nextIndex1 = (currentIndex + 1) % verticalVideos.length;
    const nextIndex2 = (currentIndex + 2) % verticalVideos.length;
    return [verticalVideos[currentIndex], verticalVideos[nextIndex1], verticalVideos[nextIndex2]];
  };

  const videoPageClasses = isVisible
    ? 'opacity-100 blur-0 transition-opacity transition-blur duration-500 ease-in-out mt-12'
    : 'opacity-0 blur-md transition-opacity transition-blur duration-500 ease-in-out';

  if (selectedVideo) {
    return (
      <div
        className={`fixed inset-0 bg-black flex flex-col items-center justify-start z-50 transition-all duration-500 ${videoPageClasses}`}
        style={{
          paddingTop: window.innerWidth < 1024 ? '4rem' : '1.5rem',
          paddingBottom: '3rem',
          overflowY: 'auto',
        }}
      >
        {/* Embed the video for horizontal ones */}
        {!selectedVideo.isVertical ? (
          <div
            dangerouslySetInnerHTML={{ __html: selectedVideo.embedCode }}
            className="w-full md:w-8/12 h-auto max-w-6xl"
            style={{ marginBottom: '3rem' }}
          />
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: selectedVideo.embedCode }}
            className="w-full md:w-8/12 h-auto max-w-6xl"
            style={{ marginBottom: '3rem' }}
          />
        )}

        <div className="text-center text-white mt-4">
          <h2 className="text-3xl font-apfel">{selectedVideo.title}</h2>
          <p className="text-5xl font-apfel font-bold">{selectedVideo.description}</p>
        </div>
        <div className="text-white mt-4 max-w-4xl p-4 rounded-lg">
          <p className="text-lg md:text-xl leading-relaxed text-center">{selectedVideo.details}</p>
        </div>

        {/* New heading and YouTube icon */}
        {selectedVideo.id === 2 && (
          <div className="text-white mt-4 max-w-4xl p-4 rounded-lg text-center">
            <h4 className="text-2xl font-bold mb-2">For full chapters of Be Our Guest</h4>
            <a
              href="https://www.youtube.com/watch?v=a6jXnmRjNA4&list=PLwmooDpEwEXIG5Gng2mO2SfPOfglHzU6Y"
              target="_blank"
              rel="noopener noreferrer"
              className="text-red-600 inline-block"
            >
              <FontAwesomeIcon icon={faYoutube} className="text-red-600 text-4xl" />
            </a>
          </div>
        )}

        <button
          onClick={handleExitFullscreen}
          className="mt-8 px-4 py-2 text-gray-200 bg-black-800 hover:bg-gray-700 rounded border border-gray-600"
        >
          Exit
        </button>
      </div>
    );
  }

  return (
    <>
      <section className="flex flex-col items-start relative py-8 px-6 md:px-16 bg-black text-white">
        <video
          className="w-full md:w-3/4 max-w-4xl h-auto mt-6 cursor-pointer"
          src={videos[0]?.src || ''}
          alt="Showreel 2024"
          onClick={() => handleVideoClick(videos[0])}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          preload="auto"
          muted
          autoPlay={isMobileOrTablet()} // Mobile ve tablet için otomatik oynatma
          loading="lazy"
          poster='/images/Showreel-kapak.jpg'
        />
        <div className="absolute bottom-16 left-4 md:left-8">
          <h2 className="text-2xl md:text-3xl font-apfel tracking-tighter font-gilroy">2024</h2>
          <p className="text-3xl md:text-5xl font-apfel tracking-tighter font-gilroy">Showreel</p>
        </div>
      </section>

      <section className="flex flex-col items-end relative py-8 px-6 md:px-16 bg-black text-white">
        <video
          className="w-full md:w-3/4 max-w-5xl h-auto mt-6 cursor-pointer"
          src={videos[1]?.src || ''}
          alt="Be Our Guest Series Showreel"
          onClick={() => handleVideoClick(videos[1])}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          preload="auto"
          muted
          autoPlay={isMobileOrTablet()} // Mobile ve tablet için otomatik oynatma
          loading="lazy"
          poster='/images/bog_kapak.jpg'
        />
        <div className="absolute bottom-8 right-4 md:right-8">
          <h2 className="text-2xl md:text-3xl font-apfel tracking-tighter font-gilroy">Be Our Guest</h2>
          <p className="text-3xl md:text-5xl font-apfel tracking-tighter font-gilroy">Series Showreel</p>
        </div>
      </section>
      <section className="flex flex-col items-start relative py-8 px-6 md:px-16 bg-black text-white">
<div
  className="relative cursor-pointer w-full md:w-3/4 max-w-4xl mt-6"
  onClick={() => handleVideoClick(videos[2])}
  onMouseEnter={() => {
   
    if (!isMobileOrTablet()) {
      const videoElement = document.getElementById(`video-${videos[2].id}`);
      if (videoElement && videoElement.readyState >= 3) {
        videoElement.play();
      }
    }
  }}
  onMouseLeave={() => {
    
    if (!isMobileOrTablet()) {
      const videoElement = document.getElementById(`video-${videos[2].id}`);
      if (videoElement) {
        videoElement.pause();
        videoElement.currentTime = 0; // Video başa döner
      }
    }
  }}
>

  

  {/* Video */}
  <video
    id={`video-${videos[2].id}`}
    className={`w-full h-auto object-cover {
      ? 'opacity-100' : 'opacity-100'
    } transition-opacity duration-300`}
    src={videos[2]?.src || ''}
    autoPlay={isMobileOrTablet()} // Mobile ve tablet için otomatik oynatma
    preload="auto"
    muted
    loop
    playsInline
    style={{ zIndex: 0 }}
    poster='/images/sm_kapak.jpg'
  />
</div>

<div
  className="absolute bottom-16 left-4 md:left-8 z-10"
  style={{ zIndex: 2 }}
>
  <h2 className="text-2xl md:text-3xl font-apfel tracking-tighter font-gilroy">
    Short Form
  </h2>
  <p className="text-3xl md:text-5xl font-apfel tracking-tighter font-gilroy">
    Videos
  </p>
</div>
</section>

      <section className="flex justify-center bg-black text-white py-4 relative">
        <button
          className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gradient-to-r from-gray-700 to-gray-900 text-white text-2xl w-12 h-12 flex items-center justify-center rounded-full sm:left-4 md:left-6 lg:left-[5%] z-50 shadow-lg hover:scale-110 hover:shadow-2xl transition-transform duration-300 ease-in-out focus:outline-none"
          onClick={handlePrevSlide}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
          </svg>
        </button>

        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 relative transition-transform duration-500 ease-in-out">
          {visibleVideos().map((video, index) => (
            <div key={index} className="relative w-full sm:w-1/2 md:w-1/2 lg:w-full">
              <video
                className="w-full h-auto max-h-[80vh] object-cover aspect-[9/16] cursor-pointer"
                src={video?.src || ''}
                alt={video?.title || 'Video'}
                onClick={() => handleVideoClick(video)}
                autoPlay={isMobileOrTablet()} // Mobile ve tablet için otomatik oynatma
                preload="auto"
                muted
                loading="lazy"
                poster={video?.poster || ''} // Her video için poster doğru şekilde ayarlandı
              />
              <div className="absolute top-2 right-2">
                <img src="/images/instagram-reel.svg" alt="Reels Icon" className="w-8 h-8" />
              </div>
            </div>
          ))}
        </div>

        <button
          className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gradient-to-r from-gray-700 to-gray-900 text-white text-2xl w-12 h-12 flex items-center justify-center rounded-full sm:right-4 md:right-6 lg:right-[5%] z-50 shadow-lg hover:scale-110 hover:shadow-2xl transition-transform duration-300 ease-in-out focus:outline-none"
          onClick={handleNextSlide}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </section>
    </>
  );
}
