import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SocialText from './SocialText';
import SocialText2 from './SocialText2';
import SocialText3 from './SocialText3';
import SocialText4 from './SocialText4';

export default function Social() {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <>
      {/* Mobil için flex-col, Web için flex-row ayarları */}
      <div className='SocialMain1 mt-18 pt-[7rem] md:pt-[4.5rem] flex flex-col justify-center md:flex-row items-center md:pl-44 pl-5 bg-white text-center md:text-left'>
        <div className="md:w-1/2">
        <h1 className='text-black font-poppins text-[58px] font-medium leading-tight' data-aos="fade-up">
  <strong>I'm Oguzcan Turan</strong>
</h1>

          <p className='text-black' data-aos="fade-up">
            <span className='inline-block bg-[#cccccc] font-poppins'> Digital Marketer & Content Creator</span>
          </p>
          <p className='text-black' data-aos="fade-up">
            Making ads-contents hard to skip.
          </p>
        </div>

        <div className="md:w-1/2 w-full flex justify-center md:justify-end pt-8 md:pt-0">
  <img className='w-full md:w-3/4 h-auto object-cover' src='images/Profile.jpeg' alt='Profile' />
</div>





      </div>

      <div className="bg-white py-6 md:py-6">
        <SocialText />
      </div>
      <div className="bg-white py-6 md:py-6">
        <SocialText2 />
      </div>
      <div className="bg-white py-6 md:py-6">
        <SocialText3 />
      </div>
      <div className="bg-white py-6 md:py-6">
        <SocialText4 />
      </div>
    </>
  );
}
